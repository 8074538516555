<template>

  <div>
    <v-badge
        class="badge-comment"
        :content="item.comments.items.length"
        :value="item.comments.items.length || false"
        color="primary darken-3"
        overlap
    >
      <v-icon
          @click="$emit('editComments', item)">
        mdi-message-text
      </v-icon>
    </v-badge>
  </div>

</template>

<script>
export default {
  name: "CommentCell",
  props: ['item']
}
</script>

<style scoped>

</style>