<template>

  <div><v-icon
      small
      class="mr-2"
      @click="$emit('editItem')"
  >
    mdi-pencil
  </v-icon>
    <v-icon
        small
        @click="$emit('deleteItem')"
    >
      mdi-delete
    </v-icon>
  </div>

</template>

<script>
export default {
  name: "ActionsCell"
}
</script>

<style scoped>

</style>