<template>
  <board
      :columns="columns"
      :filters="filters"
      :fields="fields"
      title="Codes"
      titleBtn="Nouveau code"
      title-form-edit="Modifier le code"
      title-form-new="Nouveau code"
      type="CODE"></board>
</template>

<script>

import Board from "@/components/Board";

export default {
  components: {
    Board
  },
  data() {
    return {
      columns: ["name", "comments"],
      filters: ["search"],
      fields: ["name"]
    }
  },
}

</script>