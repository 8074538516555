<template>
  <div class="d-flex justify-space-between align-center">

    <v-btn
        v-if="!isRunning"
        :disabled="loading"
        color="pink"
        icon
        @click="newTimer"
    >
      <v-icon>mdi-play</v-icon>
    </v-btn>

    <v-btn
        v-if="isRunning"
        :disabled="loading"
        color="pink"
        icon
        @click="stopTimer"
    >
      <v-icon>mdi-pause</v-icon>

    </v-btn>

    <timer-cell-dialog :display-time="displayTime"
                        :time-events="timeEvents"
                        :post-title="post.name"
                        @update="fetchTimers"></timer-cell-dialog>

  </div>
</template>

<script>
import {API} from "aws-amplify";
import {createTimeEvent, updateTimeEvent} from "@/graphql/mutations";
import TimeFormatter from "@/mixins/TimeFormatter";
import {timeEventsByPost} from "@/graphql/queries"
import TimerCellDialog from "@/components/TimerCellDialog";

export default {
  name: "TimerCell",
  components: {TimerCellDialog},
  mixins: [TimeFormatter],
  props: {
    initialTimeEvents: {
      type: Array,
      default: () => []
    },
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isRunning: false,
      loading: false,
      currentTimer: null,
      displayTime: '',
      timer: null,
      timeEvents: this.initialTimeEvents,
      totalFinishedTimes: 0
    }
  },
  created() {
    this.checkRunningTimer()
    this.$emit("updateDuration", this.totalFinishedTimes, this.post)
  },
  watch: {
    isRunning: function(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.triggerUpdateTimer()
      } else if (oldVal && !newVal) {
        clearInterval(this.timer)
      }
    }
  },
  methods: {

    async newTimer() {

      if (this.isRunning) return

      this.loading = true

      try {

        await API.graphql({
          query: createTimeEvent,
          variables: {
            input: {
              startDate: Date.now(),
              postTimeEventsId: this.post.id
            }
          },
        });

        this.loading = false

        this.fetchTimers()

      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    },
    async stopTimer() {

      if (!this.isRunning) return

      this.loading = true

      try {

        await API.graphql({
          query: updateTimeEvent,
          variables: {
            input: {
              id: this.currentTimer.id,
              endDate: Date.now()
            }
          },
        });

        this.loading = false

        this.fetchTimers()

      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    },

    async fetchTimers() {

      this.loading = true

      try {

        const response = await API.graphql({
          query: timeEventsByPost,
          variables: {postTimeEventsId: this.post.id}
        })

        this.timeEvents = response.data.timeEventsByPost.items
        this.checkRunningTimer()

        this.$emit("updateTimer", this.timeEvents, this.post)
        this.$emit("updateDuration", this.totalFinishedTimes, this.post)

        this.loading = false

      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }

    },
    triggerUpdateTimer() {
      this.timer = setInterval(() => {

        let delta = Date.now() - this.currentTimer.startDate

        delta += this.totalFinishedTimes

        this.$emit("updateDuration", delta, this.post)

        this.displayTime = this.formatTimestampToHMS(delta)

      }, 1000)
    },

    checkRunningTimer() {

      this.totalFinishedTimes = 0

      this.timeEvents.forEach(timer => {
        if (timer.endDate !== null) this.totalFinishedTimes += (timer.endDate - timer.startDate)
      })


      this.displayTime = this.formatTimestampToHMS(this.totalFinishedTimes)


      const runningTimer = this.timeEvents.find(timer => timer.endDate === null)

      if (runningTimer) {
        this.currentTimer = runningTimer
        this.isRunning = true
      } else {
        this.currentTimer = null
        this.isRunning = false
      }
    }
  }
}
</script>

<style scoped>

</style>