<template>


  <v-app>


    <div v-if="authState === 'signedin'">

      <NavigationDrawer
      />

      <AppBar/>

      <v-main>

        <router-view></router-view>


      </v-main>

    </div>

    <new-password-required v-else-if="authState === 'challenge'"></new-password-required>

    <forgot-password v-else-if="authState === 'forgotpassword'"></forgot-password>

    <sign-in v-else></sign-in>

    <notifications></notifications>


  </v-app>


</template>


<script>
import AppBar from './components/AppBar.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import Notifications from './components/Notifications.vue'
import {mapState} from 'vuex';
import DatetimePicker from 'vuetify-datetime-picker'
import Vue from "vue";
import SignIn from "@/components/SignIn";
import NewPasswordRequired from "@/components/NewPasswordRequired";
import ForgotPassword from "@/components/ForgotPassword";
import {Auth} from "aws-amplify";

Vue.use(DatetimePicker)


export default {

  name: 'TimeTracker',

  components: {
    AppBar,
    NavigationDrawer,
    Notifications,
    SignIn,
    NewPasswordRequired,
    ForgotPassword
  },

  async created() {

    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        this.$store.commit('SET_AUTH_STATE', 'signedin');
        this.$store.commit('SET_USER', user);
        this.$store.dispatch('get_users');
      }
    } catch {
      this.$store.commit('SET_AUTH_STATE', '');
    }

  },

  computed: {
    ...mapState(['authState'])
  }

};
</script>


<style lang="scss">


.badge-comment {
  .v-badge__badge, .v-badge__wrapper {
    pointer-events: none;
  }
}

.v-card__title {
  word-break: normal;
}

.checkbox-archives {
  .v-input__slot {
    margin-bottom: 0;
  }

  .v-messages {
    display: none;
  }
}


</style>

