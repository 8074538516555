/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      name
      type
      comments {
        nextToken
      }
      color
      logo
      client {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      tag
      status
      dueDate
      job {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      timeEvents {
        nextToken
      }
      archive
      user
      date
      id
      createdAt
      updatedAt
      postClientId
      postJobId
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      name
      type
      comments {
        nextToken
      }
      color
      logo
      client {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      tag
      status
      dueDate
      job {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      timeEvents {
        nextToken
      }
      archive
      user
      date
      id
      createdAt
      updatedAt
      postClientId
      postJobId
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      name
      type
      comments {
        nextToken
      }
      color
      logo
      client {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      tag
      status
      dueDate
      job {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      timeEvents {
        nextToken
      }
      archive
      user
      date
      id
      createdAt
      updatedAt
      postClientId
      postJobId
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      name
      content
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      type
      file
      createdAt
      postCommentsId
      id
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      name
      content
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      type
      file
      createdAt
      postCommentsId
      id
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      name
      content
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      type
      file
      createdAt
      postCommentsId
      id
      updatedAt
    }
  }
`;
export const createTimeEvent = /* GraphQL */ `
  mutation CreateTimeEvent(
    $input: CreateTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    createTimeEvent(input: $input, condition: $condition) {
      startDate
      endDate
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      postTimeEventsId
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTimeEvent = /* GraphQL */ `
  mutation UpdateTimeEvent(
    $input: UpdateTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    updateTimeEvent(input: $input, condition: $condition) {
      startDate
      endDate
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      postTimeEventsId
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimeEvent = /* GraphQL */ `
  mutation DeleteTimeEvent(
    $input: DeleteTimeEventInput!
    $condition: ModelTimeEventConditionInput
  ) {
    deleteTimeEvent(input: $input, condition: $condition) {
      startDate
      endDate
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      postTimeEventsId
      id
      createdAt
      updatedAt
    }
  }
`;
