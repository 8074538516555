<template>
  <div>
    <div v-if="currentStatus" :style="{backgroundColor: currentStatus.color}" class="text-center status-cell">
      {{ currentStatus.text }}
    </div>
  </div>
</template>

<script>
import StatusMixin from "@/mixins/StatusMixin";
export default {
  name: "StatusCell",
  mixins: [StatusMixin],
  props: ['status'],
  computed: {
    currentStatus: function() {
      return this.statusReferences.find(element => element.value === this.status);
    }
  }
}
</script>

<style lang="scss" scoped>
  .status-cell {
    height: 46px;
    line-height: 46px;
  }
</style>