/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      name
      type
      comments {
        nextToken
      }
      color
      logo
      client {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      tag
      status
      dueDate
      job {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      timeEvents {
        nextToken
      }
      archive
      user
      date
      id
      createdAt
      updatedAt
      postClientId
      postJobId
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      name
      content
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      type
      file
      createdAt
      postCommentsId
      id
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        content
        type
        file
        createdAt
        postCommentsId
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimeEvent = /* GraphQL */ `
  query GetTimeEvent($id: ID!) {
    getTimeEvent(id: $id) {
      startDate
      endDate
      post {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      postTimeEventsId
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTimeEvents = /* GraphQL */ `
  query ListTimeEvents(
    $filter: ModelTimeEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        startDate
        endDate
        postTimeEventsId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postByType = /* GraphQL */ `
  query PostByType(
    $type: PostType!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postByType(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        type
        color
        logo
        tag
        status
        dueDate
        archive
        user
        date
        id
        createdAt
        updatedAt
        postClientId
        postJobId
      }
      nextToken
    }
  }
`;
export const commentsByPost = /* GraphQL */ `
  query CommentsByPost(
    $postCommentsId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByPost(
      postCommentsId: $postCommentsId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        content
        type
        file
        createdAt
        postCommentsId
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const timeEventsByPost = /* GraphQL */ `
  query TimeEventsByPost(
    $postTimeEventsId: ID!
    $startDate: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimeEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timeEventsByPost(
      postTimeEventsId: $postTimeEventsId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        startDate
        endDate
        postTimeEventsId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
