<template>
        
    <v-navigation-drawer v-model="drawer" app>

        <v-img
            alt="Le Pulsar"
            class="shrink mr-2 pt-10 ml-3"
            contain
            src="../assets/le-pulsar-logo.svg"
            transition="scale-transition"
            width="140"
            />

        <v-list
            dense
            nav
        >
            <v-list-item
            v-for="item in menuData"
            :key="item.title"
            :to="item.route"
            link
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>

            </v-list-item>

            <v-list-item
                @click="dialogLogout = true"
                >

                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Déconnexion</v-list-item-title>
                </v-list-item-content>

            </v-list-item> 

        </v-list>

        <v-dialog
            v-model="dialogLogout"
            max-width="290"
            >
            <v-card>

                <v-card-title class="text-h5 text-center">
                    Voulez-vous vraiment vous déconnecter ?
                </v-card-title>

                <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn
                        color="secondary"
                        text
                        @click="dialogLogout = false"
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="red darken-1"
                        text
                        @click="logout"
                    >
                        Se déconnecter
                    </v-btn>

                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-navigation-drawer>


</template>

<script>

    import { Auth } from 'aws-amplify';
    import { mapActions } from "vuex";

    export default {

        computed: {
            drawer: {
                get() {
                    return this.$store.state.drawer
                },
                set (value) {
                    this.$store.commit('SET_DRAWER', value)
                }
            },

            menuData() {
                if (this.$store.getters.isAdmin) {
                  return this.items
                } else {
                  return this.items.filter((e) => !e.adminOnly)
                }
            }

        },
        
        data: () => ({
            items: [
                { title: 'Time Tracker', icon: 'mdi-clock-time-four-outline', route: '/', adminOnly: false },
                { title: 'Taches', icon: 'mdi-calendar-check', route: '/taches', adminOnly: false },
                { title: 'Jobs', icon: 'mdi-coffee-outline', route: '/jobs', adminOnly: false },
                { title: 'Clients', icon: 'mdi-account-tie', route: '/clients', adminOnly: true },
                { title: 'Codes', icon: 'mdi-key', route: '/codes', adminOnly: true }
            ],
            dialogLogout: false
        }),

        methods: {
            ...mapActions({
                'setSnackbar': 'notifications/setSnackbar'
            }),
            async logout() {

                try {
                    
                    await Auth.signOut();
                  this.$store.commit('SET_AUTH_STATE', '');

                } catch (error) {
                    this.setSnackbar({
                        color: "error",
                        text: error.errors[0].message
                    })
                    console.error('Signout error', error)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>