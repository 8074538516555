<template>
  <v-dialog v-model="dialog" max-width="600px" min-width="360px" persistent>
    <v-card class="px-4">
      <v-card-title class="text-center" tag="h1">Connexion</v-card-title>
      <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent>
        <v-card-text>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="loginEmail" :rules="[rules.required]" label="E-mail" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="loginPassword" :rules="[rules.required]" label="Mot de passe"
                              type="password"></v-text-field>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
            <v-row justify="space-between">
              <v-col class="d-flex" cols="12" sm="3" xsm="12">
                <v-btn color="primary" text small @click="$store.commit('SET_AUTH_STATE', 'forgotpassword')"> Mot de passe oublié ?</v-btn>
              </v-col>
              <v-col class="d-flex justify-end" cols="12" sm="4" xsm="12">
                <v-btn :disabled="!valid" :loading="loading" color="primary" type="submit" @click="validate">
                  Connexion
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import {Auth} from 'aws-amplify';

export default {
  name: "SignIn",
  methods: {
    async validate() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        try {
          const user = await Auth.signIn(this.loginEmail, this.loginPassword);

          this.$store.dispatch("set_user", user)

          this.loading = false
        } catch (error) {
          this.loading = false
          await this.$store.dispatch('notifications/setSnackbar', {
            color: "error",
            text: error
          }, {root: true})
          throw error
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  data: () => ({
    dialog: true,
    valid: true,

    loginPassword: "",
    loginEmail: "",
    loading: false,
    rules: {
      required: value => !!value || "Champ obligatoire",
    }

  })
}
</script>

<style scoped>

</style>