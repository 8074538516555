export const myPostByType = /* GraphQL */ `
    query PostByType(
        $type: PostType!
        $date: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        postByType(
            type: $type
            date: $date
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                name
                type
                color
                logo
                tag
                status
                dueDate
                archive
                date
                id
                createdAt
                updatedAt
                postClientId
                postJobId
                user
                comments {
                    items {
                        id
                    }
                }
                client {
                    name
                    color
                    logo
                }
                job {
                    id
                    name
                    color
                    tag
                    client {
                        id
                        name
                        color
                        logo
                    }
                }
                timeEvents {
                    items {
                        startDate
                        endDate
                        id
                    }
                }
            }
            nextToken
        }
    }
`;


export const listClients = /* GraphQL */ `
    query ListPosts(
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                name
                id
                color
                logo
                comments {
                    items {
                        id
                    }
                }
            }
            nextToken
        }
    }
`;


export const listJobs = /* GraphQL */ `
    query ListPosts(
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                name
                type
                id
                color
                client {
                    name
                    color
                    logo
                    id
                }
                tag
                createdAt
                updatedAt
                postClientId
                comments {
                    items {
                        id
                    }
                }
            }
            nextToken
        }
    }
`;

export const listCodes = /* GraphQL */ `
    query ListPosts(
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                name
                type
                id
                createdAt
                updatedAt
                comments {
                    items {
                        id
                    }
                }
            }
            nextToken
        }
    }
`;


export const listTaches = /* GraphQL */ `
    query ListPosts(
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                name
                type
                id
                createdAt
                updatedAt
                status
                dueDate
                user
                job {
                    name
                    color
                    client {
                        name
                        color
                        logo
                        id
                    }
                }
                comments {
                    items {
                        id
                    }
                }

            }
            nextToken
        }
    }
`;


export const listTimers = /* GraphQL */ `
    query ListPosts(
        $filter: ModelPostFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                name
                type
                id
                createdAt
                updatedAt
                job {
                    name
                    color
                    client {
                        name
                        color
                        logo
                        id
                    }
                }
                timeEvents {
                    items {
                        startDate
                        endDate
                    }
                }

            }
            nextToken
        }
    }
`;