<template>

  <v-dialog
      v-model="dialogActivator"
      max-width="500px"
  >

    <v-form ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent>

      <v-card>

        <v-card-title class="justify-center">
          <span class="text-h5">{{ titleForm }}</span>
        </v-card-title>

        <v-card-text>

          <div v-for="name in fields" :key="name">

            <v-text-field
                v-if="name === 'name'"
                v-model="editedItem.name"
                autofocus
                label="Nom"
            ></v-text-field>


            <v-text-field
                v-if="name === 'tag'"
                v-model="editedItem.tag"
                label="Tag"
            ></v-text-field>


            <template v-if="name === 'logo'">
              <v-file-input
                  v-if="!logoUrl"
                  :loading="loading"
                  accept="image/*"
                  label="Logo"
                  @change="uploadLogo"
              ></v-file-input>

              <v-card v-else
                      class="mb-9 d-flex justify-space-between align-center pa-4">
                <v-img
                    :src="logoUrl"
                    max-height="50"
                    max-width="50"
                ></v-img>
                <v-btn
                    class="mx-2"
                    color="red"
                    dark
                    fab
                    small
                    @click="removeLogo"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-card>
            </template>


            <v-color-picker
                v-if="name === 'color'"
                v-model="editedItem.color"
                class="mx-auto"
                hide-mode-switch
                mode="hexa"
            ></v-color-picker>


            <v-autocomplete
                v-if="name === 'client'"
                v-model="editedItem.postClientId"
                :items="clients"
                chips
                color="blue-grey lighten-2"
                filled
                clearable
                item-text="name"
                item-value="id"
                label="Client"
            ></v-autocomplete>


            <v-autocomplete
                v-if="name === 'job'"
                v-model="editedItem.postJobId"
                :items="jobs"
                chips
                clearable
                color="blue-grey lighten-2"
                filled
                item-text="name"
                item-value="id"
                label="Job"
            ></v-autocomplete>


            <v-menu
                v-model="menuDueDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-if="name === 'dueDate'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="editedItem.dueDate"
                    label="Échéance"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="editedItem.dueDate"
                  @input="menuDueDate = false"
              ></v-date-picker>
            </v-menu>


            <v-autocomplete
                v-if="name === 'status'"
                v-model="editedItem.status"
                :items="statusReferences"
                class="mt-4"
                chips
                clearable
                small-chips
                label="Status"
            ></v-autocomplete>


            <v-autocomplete
                v-if="name === 'user' && $store.getters.isAdmin"
                v-model="editedItem.user"
                :items="users"
                class="mt-4"
                chips
                clearable
                small-chips
                label="Utilisateur"
            ></v-autocomplete>

            <v-checkbox
                v-if="name === 'archive'"
                v-model="editedItem.archive"
                label="Archive"
            ></v-checkbox>

          </div>


          <date-time-picker v-model="editedItem.date"
                            unclearable
                             dateFormat="dd/MM/yyyy"
                             label="Date"></date-time-picker>

        </v-card-text>

        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn
              color="secondary"
              text
              @click="dialogActivator = false"
          >
            Annuler
          </v-btn>

          <v-btn
              :loading="loading"
              color="primary darken-1"
              text
              type="submit"
              @click="save"
          >
            Enregistrer
          </v-btn>

        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>

import {createPost, updatePost} from "@/graphql/mutations";
import {API, Storage} from "aws-amplify";
import CloudFrontAccess from "@/mixins/CloudFrontAccess";
import Services from "@/mixins/Services";
import StatusMixin from "@/mixins/StatusMixin";
import DateTimePicker from "@/components/DateTimePicker";


export default {
  name: "DialogForm",
  components: {DateTimePicker},
  props: ['dialog', 'currentItem', 'fields', 'titleFormNew', 'titleFormEdit'],
  mixins: [CloudFrontAccess, StatusMixin, Services],
  data() {
    return {
      valid: true,
      editedItem: {},
      loading: false,
      menuDueDate: false
    }
  },
  watch: {
    dialog: function (newVal) {

      if (newVal) {
        this.editedItem = {...this.currentItem}

        if (this.fields.includes('color') && !this.editedItem.id) {
          this.editedItem.color = '#cccccc'
        }

        if (this.fields.includes('client')) {

          this.loadClients()

        }


        if (this.fields.includes('user')) {

          this.editedItem.user = this.$store.state.user.username

        }


        if (this.fields.includes('job')) {

          this.loadJobs()

        }

      }
    }
  },
  computed: {

    dialogActivator: {

      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit("dialogUpdated", newValue)
      }
    },

    titleForm: function () {
      if (this.editedItem.id) {

        return this.titleFormEdit + " " + this.editedItem.name

      }

      return this.titleFormNew
    },

    logoUrl: function () {

      if (this.editedItem.logo) {
        return this.getCloudFrontImgLink(this.editedItem.logo);
      }

      return false

    },

    users: function() {

      return this.$store.getters.getUsersForSelect

    }
  },
  methods: {

    async save() {

      this.loading = true

      try {

        delete this.editedItem.client
        delete this.editedItem.job
        delete this.editedItem.timeEvents


        if (this.editedItem.id) {

          await API.graphql({
            query: updatePost,
            variables: {input: this.editedItem},
          });

        } else {


          await API.graphql({
            query: createPost,
            variables: {input: this.editedItem},
          });

        }

        this.$emit('postSaved')
        this.loading = false


      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }

      this.dialogActivator = false
    },

    async uploadLogo(file) {

      if (file) {

        this.loading = true
        const result = await Storage.put(file.name, file)
        this.editedItem.logo = result.key
        this.loading = false

      }

    },

    async removeLogo() {
      await Storage.remove(this.editedItem.logo)
      this.editedItem.logo = null
    },




  },
}
</script>

<style scoped>

</style>