<template>

  <div>

    <v-data-table

        ref="mainTable"
        :headers="headersTable"
        :items="data"
        :items-per-page="-1"
        :loading="areDataLoading"
        :search="search"
        class="elevation-1"
        @current-items="setFilteredData"
    >

      <template v-slot:top>

        <v-toolbar
            flat

        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>

          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <v-text-field
              v-if="filters.includes('search')"
              v-model="search"
              class="mx-4"
              clearable
              dense
              hide-details
              label="Recherche"
              outlined
              @change="filtersChanged"
          ></v-text-field>


          <v-select
              v-if="filters.includes('date')"
              v-model="currentDate"
              :items="itemsDate"
              class="mx-4"
              dense
              hide-details
              label="Période"
              outlined
              @change="filtersChanged"
          ></v-select>


          <v-select
              v-if="filters.includes('user') && $store.getters.isAdmin"
              v-model="currentUser"
              :items="$store.getters.getUsersForSelect"
              class="mx-4"
              dense
              hide-details
              label="Utilisateur"
              outlined
              clearable
              @change="filtersChanged"
          ></v-select>

          <v-select
              v-if="filters.includes('job')"
              v-model="currentJob"
              :items="jobs"
              class="mx-4"
              clearable
              dense
              hide-details
              item-text="name"
              item-value="id"
              label="Job"
              outlined
          ></v-select>

          <v-select
              v-if="filters.includes('client')"
              v-model="currentClient"
              :items="clients"
              class="mx-4"
              clearable
              dense
              hide-details
              item-text="name"
              item-value="id"
              label="Client"
              outlined
          ></v-select>

          <v-checkbox
              v-if="filters.includes('archive')"
              v-model="isArchive"
              class="mr-4 checkbox-archives"
              label="Archives"
              @change="filtersChanged"
          ></v-checkbox>

          <v-btn
              v-if="filters.includes('download')"
              class=""
              color="grey darken-2"
              icon
              @click="csvExport"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              class="mb-2"
              color="secondary"
              dark
              @click="newForm"
          >
            {{ titleBtn }}
          </v-btn>

        </v-toolbar>

      </template>


      <!-- Rows -->

      <template v-slot:item="{ item }">

        <tr>

          <td v-for="header in headersTable" :key="header.name + item.id"
              :class="{ 'text-end': header.align === 'end' , 'd-none': header.align === 'none', 'timer-cell': header.name === 'timer'}">


            <template v-if="header.name === 'name'">{{ item.name }}</template>

            <user-chips v-if="header.name === 'user'" :user-id="item.user"></user-chips>

            <template v-if="header.name === 'hiddenname'">{{ item.name }}</template>

            <client-chips v-if="header.name === 'client'" :item="item"></client-chips>

            <client-chips v-if="header.name === 'clientJob'" :item="item.client"></client-chips>

            <client-chips v-if="header.name === 'jobClient' && item.job && (item.job.client !== undefined)"
                          :item="item.job.client"></client-chips>

            <comment-cell v-if="header.name === 'comments'" :item="item"
                          @editComments="editComments(item)"></comment-cell>

            <actions-cell v-if="header.name === 'actions'" @deleteItem="deleteItem(item)"
                          @dialogUpdated="dialog = $event" @editItem="editItem(item)"></actions-cell>

            <JobChips v-if="header.name === 'tag'" :item="item"></JobChips>

            <template v-if="header.name === 'dueDate'">{{ formatDate(item.dueDate) }}</template>

            <template v-if="header.name === 'date'">{{ formatDate(item.date) }}</template>

            <status-cell v-if="header.name === 'status'" :status="item.status"></status-cell>

            <JobChips v-if="header.name === 'job'" :item="item.job"></JobChips>

            <timer-cell v-if="header.name === 'timer'"
                        :initial-time-events="item.timeEvents.items"
                        :post="item"
                        @updateDuration="updateDuration"
                        @updateTimer="updateTimer"></timer-cell>

          </td>

        </tr>

      </template>

      <template v-slot:body.append>

        <tr>

          <td v-for="header in headersTable" :key="'total' + header.name"
              :class="{ 'text-end': header.align === 'end' || header.name === 'timer', 'd-none': header.align === 'none' }">
            <span v-if="header.name === 'actions'" class="text-overline">Total : {{ filteredData.length }}</span>
            <span v-if="header.name === 'timer'" class="text-overline">{{ formatTimestampToHMS(duration) }}</span>
          </td>

        </tr>

      </template>


    </v-data-table>

<!--    <div class="board-footer">

      <div class="inner ml-auto pa-3">
        <v-btn
            :disabled="currentPage === 0"
            icon
            @click="prevPage">
          <v-icon dark>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <v-btn
            :disabled="tokens[currentPage + 1] === undefined"
            icon
            @click="nextPage">
          <v-icon dark>
            mdi-chevron-right
          </v-icon>
        </v-btn>

      </div>

    </div>-->


    <dialog-form :current-item="currentItem"
                 :dialog="dialog"
                 :fields="fields"
                 :title-form-edit="titleFormEdit"
                 :title-form-new="titleFormNew"
                 @dialogUpdated="dialog = $event"
                 @postSaved="fetchData"></dialog-form>

    <dialog-delete :current-item="currentItem"
                   :dialog-delete="dialogDelete"
                   @dialogDeleteUpdated="dialogDelete = $event"
                   @itemDeleted="fetchData"

    ></dialog-delete>


    <comments-bottom-sheet :current-item="currentItem"
                           :show-comments="showComments"
                           @dialogUpdated="showComments = $event"
    ></comments-bottom-sheet>

  </div>

</template>

<script>

import DialogForm from "@/components/DialogForm";
import DialogDelete from "@/components/DialogDelete";
import {myPostByType} from "@/graphql/myQueries";
import {API} from "aws-amplify";
import ActionsCell from "@/components/ActionsCell";
import CommentCell from "@/components/CommentCell";
import CommentsBottomSheet from "@/components/CommentsBottomSheet";
import ClientChips from "@/components/ClientChips";
import JobChips from "@/components/JobChips";
import TimeFormatter from "@/mixins/TimeFormatter";
import StatusCell from "@/components/StatusCell";
import TimerCell from "@/components/TimerCell";
import Services from "@/mixins/Services";
import UserChips from "@/components/UserChips";

export default {
  name: "Board",
  mixins: [TimeFormatter, Services],
  components: {
    UserChips,
    StatusCell,
    CommentsBottomSheet,
    CommentCell,
    DialogForm,
    ActionsCell,
    DialogDelete,
    ClientChips,
    JobChips,
    TimerCell
  },
  data() {
    return {
      areDataLoading: false,
      data: [],
      search: '',
      isArchive: false,
      dialog: false,
      dialogDelete: false,
      tokens: [],
      nextToken: '',
      currentItem: {},
      //currentPage: 0,
      showComments: false,
      currentUser: this.$store.state.user.username,
      currentDate: "today",
      currentClient: "",
      currentJob: "",
      filteredData: [],
      recompute: true,
      itemsDate: [
        {
          text: "Aujourd'hui",
          value: "today"
        },
        {
          text: "Ce mois-ci",
          value: "currentMonth"
        },
        {
          text: "Le mois dernier",
          value: "previousMonth"
        },
        {
          text: "Toutes",
          value: "all"
        }
      ]
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    title: String,
    titleBtn: String,
    type: String,
    titleFormNew: String,
    titleFormEdit: String
  },
  created() {

    if (this.filters.includes("client")) this.loadClients()
    if (this.filters.includes("job")) this.loadJobs()


    this.fetchData()
  },
  computed: {

    headersTable: function () {

      let headers = []


      this.columns.forEach((name) => {
        if (name === "name") {
          headers.push({
            text: 'Nom',
            align: 'start',
            name: name,
            value: "name"
          })
        } else if (name === "tag") {
          headers.push({
            text: 'Tag',
            align: 'start',
            name: name,
            value: "tag",
            sortable: false
          })
        } else if (name === "hiddenname") {
          headers.push({
            text: 'Nom',
            align: 'none',
            value: "name",
            class: 'd-none',
            name: name
          })
        } else if (name === "client") {
          headers.push({
            text: 'Client',
            value: 'name',
            align: 'start',
            name: name,
            sortable: true
          })
        } else if (name === "comments") {
          headers.push({
            text: 'Notes',
            value: 'comments',
            align: 'end',
            name: name,
            sortable: false
          })
        } else if (name === "clientJob") {
          headers.push({
            text: 'Client',
            value: 'client',
            name: name,
            sortable: true,
            sort: (a, b) => a.name.localeCompare(b.name)
          })
        } else if (name === "dueDate") {
          headers.push({
            text: 'Échéance',
            value: 'dueDate',
            name: name,
            sortable: true,
            sort: (a, b) => new Date(b) - new Date(a)
          })
        } else if (name === "date") {
          headers.push({
            text: 'Date',
            value: 'date',
            name: name,
            sortable: true,
            sort: (a, b) => new Date(b) - new Date(a)
          })
        } else if (name === "status") {
          headers.push({
            text: 'Statut',
            value: 'status',
            name: name,
            sortable: true
          })
        } else if (name === "job") {
          headers.push({
            text: 'Job',
            value: 'job',
            name: name,
            sortable: false,
            filter: value => {
              if (!this.currentJob) return true
              if (!value) return false
              return value.id === this.currentJob
            },
          })

        } else if (name === "jobClient") {
          headers.push({
            text: 'Client',
            value: 'job',
            name: name,
            sortable: false,
            filter: value => {
              if (!this.currentClient) return true
              if (!value || value.client === undefined) return false
              return value.client.id === this.currentClient
            }
          })

        } else if (name === "timer") {
          headers.push({
            text: 'Timer',
            value: 'name',
            name: name,
            sortable: false
          })

        } else if (name === "user") {
          headers.push({
            text: 'Utilisateur',
            value: 'user',
            name: name,
            sortable: true
          })
        }
      })

      headers.push({
        text: 'Actions',
        align: 'end',
        value: 'actions',
        name: 'actions',
        sortable: false
      })

      return headers
    },

    duration: function() {
      let duration = 0
      this.recompute
      this.filteredData.forEach(item => {
        if (item.duration !== undefined) duration += item.duration
      })
      return duration
    }
  },
  watch: {
    showComments: function (newVal) {
      if (!newVal) this.fetchData()
    }
  },

  methods: {

    filtersChanged() {
      this.tokens = []
      this.fetchData()
    },

    editItem(item) {

      this.currentItem = {...item}
      this.currentItem.date = new Date(item.date)

      delete this.currentItem.createdAt
      delete this.currentItem.updatedAt
      delete this.currentItem.comments
      delete this.currentItem.duration

      this.dialog = true

    },
    deleteItem(item) {
      this.currentItem = item
      this.dialogDelete = true
    },

    async fetchData() {

      this.areDataLoading = true


      let variables = {}

      if ( (this.type === 'CODE' || this.type === 'CLIENT') && !this.$store.getters.isAdmin) return


      // Set Type

      variables.type = this.type


      // Set Sort Direction

      variables.sortDirection = "DESC"


      // Set Tokens

      if (this.nextToken) {
        variables.nextToken = this.nextToken
      }

      // Set Filters

      let filter = {}


      // Filter Archive
      if (this.filters.includes("archive")) {
        if (this.isArchive) {
          filter.archive = {eq: true}
        } else {
          filter.archive = {ne: true}
        }

      }

      if ( this.filters.includes("user") ) {
        if (!this.$store.getters.isAdmin) {
          filter.user = {eq: this.$store.state.user.username}
        } else if (this.currentUser) {
          filter.user = {eq: this.currentUser}
        }
      }

      variables.filter = filter


      // Filter Date
      if (this.filters.includes("date") && this.currentDate !== "all") {

        const today = new Date()
        let startDate, endDate

        const currentFullYear = today.getFullYear()
        const currentMonth = today.getMonth()
        const currentDate = today.getDate()


        switch (this.currentDate) {

          case 'today':
            startDate = new Date(currentFullYear, currentMonth, currentDate)
            endDate = new Date(currentFullYear, currentMonth, currentDate + 1)
            break
          case 'currentMonth':
            startDate = new Date(currentFullYear, currentMonth, 1)
            endDate = new Date(currentFullYear, currentMonth + 1, 1)
            break

          case 'previousMonth':
            startDate = new Date(currentFullYear, currentMonth - 1, 1)
            endDate = new Date(currentFullYear, currentMonth, 1)
            break

        }

        variables.date = {between: [startDate, endDate]}

      }




      try {

        const response = await API.graphql({query: myPostByType, variables: variables})

        if (this.nextToken) {
          this.data = this.data.concat(response.data.postByType.items)
        } else {
          this.data = response.data.postByType.items
        }


        this.nextToken = response.data.postByType.nextToken

        if (this.nextToken) {
          this.fetchData()
        } else {
          this.areDataLoading = false
        }



      } catch (e) {
        this.areDataLoading = false
        console.warn(e);
        this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    },

    newForm() {

      this.currentItem = {}

      this.currentItem.date = new Date()
      this.currentItem.type = this.type

      this.dialog = true
    },

    /*nextPage() {
      this.currentPage++
      this.fetchData()
    },

    prevPage() {
      this.currentPage--
      this.fetchData()
    },*/

    editComments(item) {
      this.currentItem = item
      this.showComments = true
    },

    updateTimer(timeEvents, post) {

      for (const obj of this.data) {
        if (obj.id === post.id) {
          obj.timeEvents.items = timeEvents;
          break;
        }
      }

    },

    updateDuration(duration, post) {
      for (const obj of this.data) {
        if (obj.id === post.id) {
          obj.duration = duration;
          break;
        }
      }

      this.recompute = !this.recompute

    },

    setFilteredData(event) {
      this.filteredData = event
    },


    csvExport() {


      const fieldSep = "\t"

      let csvContent = "";
      csvContent += "Nom" + fieldSep + "Temps" + fieldSep + "Job" + fieldSep + "Date\n"


      this.filteredData.forEach(item => {

        const duration = item.duration/86400000

        let date = new Date(item.date)

        const dateString = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

        csvContent += '"' + item.name + '"' + fieldSep
        csvContent += '"' + duration.toString().replace('.',',') + '"' + fieldSep
        csvContent += '"'
        csvContent += (item.job !== undefined) ? item.job.name : ""
        csvContent += '"' + fieldSep
        csvContent += '"' + dateString + '"' + fieldSep
        csvContent += "\n"
      })


      csvContent.replace(/(^\[)|(\]$)/gm, "");

      const fileName = "export-" + new Date().toJSON() + ".csv"

      //csvContent = csvContent;

      var universalBOM = "\uFEFF";


      const data = 'data:text/csv; charset=utf-8,' + encodeURI(universalBOM+csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", fileName);
      link.click();
    }
  }

}
</script>

<style scoped>


.timer-cell {
  width: 16%;
}
</style>
