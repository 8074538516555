<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <v-card class="px-4">
      <v-card-title tag="h1" class="text-center">Définir votre nouveau mot de passe</v-card-title>
      <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent>
      <v-card-text>

          <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="loginPassword" label="Mot de passe" :rules="[rules.required, rules.min]"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" xsm="12">
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
              <v-btn :disabled="!valid" :loading="loading" color="primary" type="submit" @click="validate"> Enregistrer </v-btn>
            </v-col>
          </v-row>
          </v-container>

      </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import { Auth } from 'aws-amplify';
import { mapState} from "vuex";

export default {
  name: "SignIn",
  computed: {
    ...mapState(['user'])
  },
  methods: {
    async validate() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        try {
          const user = await Auth.completeNewPassword(this.user, this.loginPassword);
          this.$store.dispatch("set_user", user)
          this.loading = false
        } catch (error) {
          this.loading = false
          await this.$store.dispatch('notifications/setSnackbar', {
            color: "error",
            text: error
          }, {root: true})
          throw error
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  data: () => ({
    dialog: true,
    valid: true,

    loginPassword: "",
    loginEmail: "",
    loading: false,
    rules: {
      required: value => !!value || "Champ obligatoire",
      min: v => (v && v.length >= 8) || "8 caractères minimum"
    }

  })
}
</script>

<style scoped>

</style>