import { API } from "aws-amplify"
import {listClients, listCodes, listJobs, listTaches, listTimers} from "@/graphql/myQueries"
import {createPost, deletePost, updatePost} from "@/graphql/mutations";


export default {
    namespaced: true,
    state() {
        return {
            areClientsLoading: false,
            clients: [],
            areJobsLoading: false,
            jobs: [],
            areCodesLoading: false,
            codes: [],
            areTachesLoading: false,
            taches: [],
            areTimersLoading: false,
            timers: []
        }
    },
    mutations: {
        SET_ARE_CLIENTS_LOADING (state, bool) {
            state.areClientsLoading = bool
        },
        SET_CLIENTS (state, clients) {
            state.clients = clients
        },
        SET_ARE_JOBS_LOADING (state, bool) {
            state.areJobsLoading = bool
        },
        SET_JOBS (state, jobs) {
            state.jobs = jobs
        },
        SET_ARE_CODES_LOADING (state, bool) {
            state.areCodesLoading = bool
        },
        SET_CODES (state, codes) {
            state.codes = codes
        },
        SET_ARE_TACHES_LOADING (state, bool) {
            state.areTachesLoading = bool
        },
        SET_TACHES (state, taches) {
            state.taches = taches
        },
        SET_ARE_TIMERS_LOADING (state, bool) {
            state.areTimersLoading = bool
        },
        SET_TIMERS (state, timers) {
            state.timers = timers
        }
    },
    actions: {

        async fetchAllClients ( { commit, dispatch } ) {

            commit('SET_ARE_CLIENTS_LOADING', true)

            try {

                let filter = {
                    type: {
                        eq: 'CLIENT'
                    }
                };

                const response = await API.graphql({query: listClients, variables: { filter: filter }})
                commit('SET_CLIENTS', response.data.listPosts.items)
                commit('SET_ARE_CLIENTS_LOADING', false)
            } catch (e) {
                commit('SET_ARE_CLIENTS_LOADING', false)
                console.warn(e);
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }
        },

        async fetchAllJobs ( { commit, dispatch }, filter ) {

            commit('SET_ARE_JOBS_LOADING', true)

            try {

                filter.type = {
                    eq: 'JOB'
                }

                const response = await API.graphql({query: listJobs, variables: { filter: filter }})
                commit('SET_JOBS', response.data.listPosts.items)
                commit('SET_ARE_JOBS_LOADING', false)
            } catch (e) {
                commit('SET_ARE_JOBS_LOADING', false)
                console.warn(e);
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }

        },




        async fetchAllTimers ( { commit, dispatch }, filter ) {

            commit('SET_ARE_TIMERS_LOADING', true)

            try {

                filter.type = {
                    eq: 'TIMER'
                }

                const response = await API.graphql({query: listTimers, variables: { filter: filter }})
                commit('SET_TIMERS', response.data.listPosts.items)
                commit('SET_ARE_TIMERS_LOADING', false)
            } catch (e) {
                commit('SET_ARE_TIMERS_LOADING', false)
                console.warn(e);
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }
        },

        async fetchAllCodes ( { commit, dispatch } ) {

            commit('SET_ARE_CODES_LOADING', true)

            try {

                let filter = {
                    type: {
                        eq: 'CODE'
                    }
                };

                const response = await API.graphql({query: listCodes, variables: { filter: filter }})
                commit('SET_CODES', response.data.listPosts.items)
                commit('SET_ARE_CODES_LOADING', false)
            } catch (e) {
                commit('SET_ARE_CODES_LOADING', false)
                console.warn(e);
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }
        },

        async fetchAllTaches ( { commit, dispatch } ) {

            commit('SET_ARE_TACHES_LOADING', true)

            try {

                let filter = {
                    type: {
                        eq: 'TASK'
                    }
                };

                const response = await API.graphql({query: listTaches, variables: { filter: filter }})
                commit('SET_TACHES', response.data.listPosts.items)
                commit('SET_ARE_TACHES_LOADING', false)
            } catch (e) {
                commit('SET_ARE_TACHES_LOADING', false)
                console.warn(e);
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }
        },

        async createPost ( { dispatch }, post) {

            try {

                await API.graphql({
                    query: createPost,
                    variables: {input: post},
                });

            } catch (e) {
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }
        },


        async updatePost ( { dispatch }, post) {
            try {
                await API.graphql({
                    query: updatePost,
                    variables: {input: post},
                });
            } catch (e) {
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }

        },

        async deletePost ( { dispatch }, post) {

            try {
                await API.graphql({
                    query: deletePost,
                    variables: {input: post},
                });
            } catch (e) {
                dispatch('notifications/setSnackbar', {
                    color: "error",
                    text: e
                }, { root: true })
                throw e
            }


        }

    }
}