<template>
    <div>
        <v-slide-y-transition
            class="py-0"
            group
        >

            <v-snackbar
                v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
                :key="snackbar.key"
                v-model="snackbar.showing"
                app
                right
                top
                absolute
                :color="snackbar.color"
                :style="`top: ${index * 60}px`"
                >
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    text
                    small
                    v-bind="attrs"
                    @click="snackbar.showing = false"
                    >
                        Fermer
                    </v-btn>
                </template>

            </v-snackbar>

        </v-slide-y-transition>

    </div>
</template>

<script>
    import { mapState } from "vuex"

    export default {
        computed: {
            ...mapState({
                snackbars: (state) => state.notifications.snackbars
            })
        }
    }
</script>

<style lang="scss" scoped>
  ::v-deep .v-snack--absolute {
    z-index: 1000;
  }
</style>