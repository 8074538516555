import { v4 as uuidv4 } from 'uuid';

export default {
    
    namespaced: true,

    state() {
        return {
            snackbars: []
        }
    },

    mutations: {
        SET_SNACKBAR(state, snackbar) {
            state.snackbars.push(snackbar)
            //state.snackbars.splice(state.snackbars.length, 0, snackbar)
        }
    },

    actions: {
        setSnackbar({ commit }, snackbar) {
            snackbar.showing = true
            snackbar.key = uuidv4()
            snackbar.color = snackbar.color || "secondary"
            commit('SET_SNACKBAR', snackbar)
        } 
    }


}