<template>
    <span v-if="item">
      <span v-if="item && item.tag" :style="{color: item.color}">#{{ item.tag }}</span>
      <span v-else :style="{color: item.color}">#{{ item.name }}</span>
    </span>
</template>

<script>
    export default {
        props: [
            'item'
        ]
    }
</script>