import Vue from 'vue';
import Vuetify from "vuetify";
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            fr
        },
        current: 'fr'
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#88d8cc",
                error: "#B71C1C",
                table: {
                    hover: "#ff0000"
                }
            },
        }
     },
});
