<template>
  <board
      :columns="columns"
      :filters="filters"
      :fields="fields"
      title="Clients"
      titleBtn="Nouveau client"
      title-form-edit="Modifier le client"
      title-form-new="Nouveau client"
      type="CLIENT"></board>
</template>

<script>

import Board from "@/components/Board";

export default {
  components: {
    Board
  },
  data() {
    return {
      columns: ["hiddenname", "client", "comments"],
      filters: ["search", "archive"],
      fields: ["name", "logo", "color", "archive"]
    }
  },
}

</script>