import Vue from 'vue'
import Vuex from 'vuex'
import NotificationsModule from './modules/notifications.js'
import PostsModule from './modules/posts'
import {API} from "aws-amplify";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    user: undefined,
    authState: undefined,
    unsubscribeAuth: undefined,
    users: []
  },
  mutations: {

    SET_DRAWER (state, payload) {
      state.drawer = payload
    },

    SET_UNSUBSCRIBE_AUTH (state, unsubscribeAuth) {
      state.unsubscribeAuth = unsubscribeAuth
    },

    SET_AUTH_STATE (state, authState) {
      state.authState = authState
    },

    SET_USER (state, user) {
      state.user = user
    },

    SET_USERS (state, users) {
      state.users = users
    }

  },
  actions: {
    set_user({commit, dispatch}, user) {

      commit('SET_USER', user)

      if (user.challengeName !== undefined) {
        commit('SET_AUTH_STATE', 'challenge');
      } else {
        commit('SET_AUTH_STATE', 'signedin');
        dispatch('get_users')
      }
    },
    async get_users({state, dispatch, commit}) {

      const jwtToken = state.user.signInUserSession.accessToken.jwtToken

      let apiName = 'AdminQueries';
      let myInit = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken
        }
      }


      try {
        const users = await API.get(apiName, '/listUsers', myInit)
        commit('SET_USERS', users.Users)
      } catch (e) {
        console.warn(e);
        dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    }
  },
  getters: {
    isAdmin(state) {
      if (state.authState === 'signedin' && 'cognito:groups' in state.user.signInUserSession.accessToken.payload) {
        return state.user.signInUserSession.accessToken.payload['cognito:groups'].includes('administrateurs')
      }

      return false

    },

    getUserByID: (state) => (id) => {
      return state.users.find(user => user.Username === id)
    },

    getUsersForSelect(state) {

      let users = []
      state.users.forEach(e => {

        let nameAttribute = e.Attributes.find(a => a.Name === 'name')

        if (!nameAttribute) return

        users.push ({
          text: nameAttribute.Value,
          value: e.Username
        })
      })

      return users
    }
  },
  modules: {
    notifications: NotificationsModule,
    posts: PostsModule
  }
})
