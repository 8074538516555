
export default {
    methods: {
        getCloudFrontImgLink(key) {

            const CDN_URL = process.env.VUE_APP_CDN_URL || "https://d3h8zgyoemh8bx.cloudfront.net/";

            return CDN_URL + key

        }
    }
}