export default {

    methods: {
        formatDate (dt) {

            if (!dt) return

            return Intl.DateTimeFormat('fr', {
                    //weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',

                    // Time zone to express it in
                    timeZone: 'Europe/Paris'}).format(new Date(dt))

        },

        formatTime (dt) {
            return Intl.DateTimeFormat('fr', {

                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',

                    // Time zone to express it in
                    timeZone: 'Europe/Paris'}).format(new Date(dt))

        },

        formatTimestampToHMS (dt) {

            const d = Number(Math.floor(dt/1000))
            const h = Math.floor(d / 3600);
            const m = Math.floor(d % 3600 / 60);
            const s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + "h " : "";
            var mDisplay = m > 0 ? m + "m " : "";
            var sDisplay = s > 0 ? s + "s" : "";

            return (hDisplay + mDisplay + sDisplay)
        }
    }
    

}