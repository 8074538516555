<template>
  <div><board
      :columns="columns"
      :filters="filters"
      :fields="fields"
      title="Time tracker"
      titleBtn="Nouveau temps"
      title-form-edit="Modifier le temps"
      title-form-new="Nouveau temps"
      type="TIMER"></board>
  </div>
</template>

<script>

import Board from "@/components/Board";

export default {
  components: {
    Board
  },
  data() {
    return {
      columns: ["name", "user", "job", "jobClient", "timer", "date"],
      filters: ["search", "date", "user", "job", "client", "download"],
      fields: ["name", "job", "user"]
    }
  },
}

</script>