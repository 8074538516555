import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import store from './store'
import Amplify from 'aws-amplify'
import config from './aws-exports'
import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';


applyPolyfills().then(() => {
  defineCustomElements(window);
});



Amplify.configure(config)

Vue.config.productionTip = false
Vue.config.ignoredElements = [/amplify-\w*/];



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')