<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="displayTime"
          class="text-time"
          color="red lighten-2"
          hide-details
          pink
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>

    <v-card>

      <v-card-title class="text-h5">
        {{ postTitle }}
      </v-card-title>

      <v-card-text class="">


        <v-container fluid>

          <v-row v-for="item in finishedTimeEvents" :key="item.id" dense>

            <v-col cols="12"
                   lg="5">

              <date-time-picker v-model="item.startDateFull"
                                label="Date de début"
                                unclearable
                                :disabled="loading"
                                @input="update(item)"></date-time-picker>

            </v-col>
            <v-col cols="12"
                   lg="5">
              <date-time-picker v-model="item.endDateFull"
                                label="Date de fin"
                                unclearable
                                :disabled="loading"
                                @input="update(item)"></date-time-picker>
            </v-col>

            <v-col cols="12"
                   lg="2">
              <v-btn
                  class="mx-2"
                  color="red"
                  dark
                  fab
                  small
                  :loading="loading"
                  @click="removeTimeEvent(item)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-col>

          </v-row>

        </v-container>

      </v-card-text>


      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateTimePicker from "@/components/DateTimePicker";
import {updateTimeEvent, deleteTimeEvent} from "@/graphql/mutations";
import {API} from "aws-amplify";

export default {
  name: "TimerCellDialog",
  components: {DateTimePicker},
  props: {
    displayTime: {
      type: String
    },
    timeEvents: {
      type: Array,
      default: () => []
    },
    postTitle: {
      type: String
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    finishedTimeEvents: function () {
      let finishedTimeEvents = this.timeEvents.filter(item => item.endDate !== null)
      finishedTimeEvents.map(item => {
        item.startDateFull = new Date(item.startDate)
        item.endDateFull = new Date(item.endDate)
        return item
      })
      return finishedTimeEvents
    }
  },
  methods: {
    async update(item){
      this.loading = true
      try {

        const updatedItem = {
          id: item.id,
          startDate: item.startDateFull.getTime(),
          endDate: item.endDateFull.getTime()
        }

        await API.graphql({
          query: updateTimeEvent,
          variables: {input: updatedItem}
        })

        this.$emit("update")

        this.loading = false

      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    },
    async removeTimeEvent(item) {

      this.loading = true
      try {

        const updatedItem = {
          id: item.id
        }

        await API.graphql({
          query: deleteTimeEvent,
          variables: {input: updatedItem}
        })

        this.$emit("update")
        this.loading = false


      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.text-time {
  min-width: 200px;
  text-align: right;
  padding-top: 0;
  margin-top: 0;

}

.position-relative {
  position: relative;
}


::v-deep .v-input__slot {
  &:before, &:after {
    display: none;
  }
}

.text-time ::v-deep .v-text-field__slot input {
  display: inline;
  text-align: right !important;
}


</style>