<template>
  <board
      :columns="columns"
      :filters="filters"
      :fields="fields"
      title="Tâches"
      titleBtn="Nouvelle tâche"
      title-form-edit="Modifier la tâche"
      title-form-new="Nouvelle tâche"
      type="TASK"></board>
</template>

<script>

import Board from "@/components/Board";

export default {
  components: {
    Board
  },
  data() {
    return {
      columns: ["name", "user", "dueDate", "status", "job", "jobClient", "comments"],
      filters: ["search", "archive", "user"],
      fields: ["name", "status", "dueDate", "job", "archive", "user"]
    }
  },
}

</script>