<template>

  <v-dialog v-model="dialogActivator" max-width="500px" persistent>

    <v-card>

      <v-card-title class="text-h5">Voulez-vous vraiment supprimer {{ texteType }}&nbsp;?</v-card-title>

      <v-card-actions>

        <v-spacer></v-spacer>
        <v-btn color="secondary" text :disabled="loading" @click="dialogActivator = false">Annuler</v-btn>
        <v-btn color="red darken-1" text @click="deleteItemConfirm" :loading="loading">Supprimer</v-btn>
        <v-spacer></v-spacer>

      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>

import {deletePost} from "@/graphql/mutations";
import {API} from "aws-amplify";

export default {
  props: ['dialogDelete', 'currentItem'],
  emits: ['dialogDeleteUpdated', 'itemDeleted'],
  data() {
    return {
      loading: false
    }
  },
  computed: {

    dialogActivator: {

      get: function () {
        return this.dialogDelete
      },
      set: function (newValue) {
        this.$emit("dialogDeleteUpdated", newValue)
      }
    },

    texteType: function() {

      let textType

      switch (this.currentItem.type) {
        case "JOB":
          textType = "ce job"
              break

        case "CLIENT":
          textType = "ce client"
          break

        case "TASK":
          textType = "cette tâche"
          break

        case "CODE":
          textType = "ce code"
          break

        case "TIMER":
          textType = "ce temps"
          break
        default:
          textType = "ce post"
      }

      return textType
    }
  },
  methods: {
    async deleteItemConfirm() {

      this.loading = true

      try {
        await API.graphql({
          query: deletePost,
          variables: {
            input: {
              id: this.currentItem.id
            },
          }
        });

        this.loading = false


      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }

      this.dialogActivator = false

      this.$emit("itemDeleted")

    },
  }
}

</script>