import Vue from 'vue'
import VueRouter from 'vue-router'
import TimeTracker from '../views/TimeTracker.vue'
import Jobs from '../views/Jobs.vue'
import NotFound from '../views/NotFound.vue'
import Clients from '../views/Clients.vue'
import Codes from "@/views/Codes";
import Taches from "@/views/Taches";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: TimeTracker
  },
  {
    path: '/taches',
    name: 'Taches',
    component: Taches,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
  },
  {
    path: '/codes',
    name: 'Codes',
    component: Codes,
  },
  {
    path: '/:wrongPath(.*)',
    name: 'NotFound',
    component: NotFound

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.needLoggedIn && !localStorage.getItem('isLoggedIn')) {
    next({ name: 'Login' });
  } else {
    next();
  }
})

export default router
