import {API} from "aws-amplify";
import {myPostByType} from "@/graphql/myQueries";

export default {
    data() {
        return {
            clients: [],
            jobs: []
        }
    },
    methods: {
        async loadClients() {
            const variables = {
                type: "CLIENT",
                filter: {
                    archive: {
                        ne: true
                    }
                }
            }
            
			this.clients = await fetchPostsNextToken({variables: variables, items: []})

        },


        async loadJobs() {
            const variables = {
                type: "JOB",
                filter: {
                    archive: {
                        ne: true
                    }
                }
            }

            this.jobs = await fetchPostsNextToken({variables: variables, items: []})


        }
    }
}


async function fetchPostsNextToken({ variables, items = [] }) {

	const result = await API.graphql({ query: myPostByType, variables: variables });

	const res = result.data.postByType; // res = { items: [], nextToken: '' }
	if (result.data.postByType) {
		items.push(...result.data.postByType.items);
	}

	if (!res.nextToken) return items
  
	variables.nextToken = res.nextToken;

	return fetchPostsNextToken({ variables, items });
  }