<template>
  <v-chip v-if="userId">
    <v-avatar  v-if="getPicture" left>
      <v-img :src="logoUrl"
             max-height="30"
             max-width="30"
             :alt="getAttribute('name')"
             ></v-img>
    </v-avatar>

    {{ getAttribute('name') }}
  </v-chip>
</template>

<script>

import CloudFrontAccessMixin from '@/mixins/CloudFrontAccess'

export default {
  name: "UserChips",
  mixins: [
    CloudFrontAccessMixin
  ],
  props: [
    'userId'
  ],
  computed: {
    logoUrl() {
      return this.getCloudFrontImgLink(this.getPicture)
    },
    user() {
      return this.$store.getters.getUserByID(this.userId)
    },

    getPicture() {
      return this.getAttribute('picture')
    }

  },

  methods: {
    getAttribute(attr) {

      if (this.user) {

        let attributeObject = this.user.Attributes.find(e => e.Name === attr)

        if (attributeObject) return attributeObject.Value

      }

      return false
    }
  }
}
</script>

<style scoped>

.picture {
  border: 2px solid #e1e1e1;
}

</style>