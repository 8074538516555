<template>

  <div>
    <v-chip
        v-if="item"
        :style="{borderColor: item.color}"
        outlined
        label
    >

      <v-img v-if="item.logo" :src="logoUrl"
             max-height="20"
             max-width="20"
             transition="false"
             class="mr-3"></v-img>
      {{ item.name }}
    </v-chip>
  </div>

</template>

<script>

import CloudFrontAccessMixin from '@/mixins/CloudFrontAccess'


export default {
  mixins: [
    CloudFrontAccessMixin
  ],
  props: [
    'item'
  ],
  computed: {
    logoUrl() {
      return this.getCloudFrontImgLink(this.item.logo)
    }
  }
}
</script>


<style lang="scss" scoped>



::v-deep .v-chip:hover:before {
  display: none !important;
}

</style>