<template>
  <v-dialog v-model="dialog" max-width="600px" min-width="360px" persistent>
    <v-card class="px-4">
      <v-card-title class="text-center" tag="h1">Mot de passe oublié</v-card-title>
      <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent>
        <v-card-text>

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field :disabled="formSubmittted" v-model="loginEmail" :rules="[rules.required]" label="E-mail" required></v-text-field>
              </v-col>
              <v-col cols="12" v-if="formSubmittted">
                <v-text-field v-model="loginPassword" label="Votre nouveau mot de passe" :rules="[rules.required, rules.min]"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="formSubmittted">
                <v-text-field v-model="code" label="Code de validation (reçu par e-mail)"></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row justify="space-between">
              <v-col class="d-flex" cols="12" sm="3" xsm="12">
                <v-btn color="primary" text small @click="$store.commit('SET_AUTH_STATE', '')"> Retour à l'écran de connexion</v-btn>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3" xsm="12">
                <v-btn :disabled="!valid" :loading="loading" color="primary" type="submit" @click="validate">
                  Connexion
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import {Auth} from 'aws-amplify';

export default {
  name: "ForgotPassword",
  methods: {
    async validate() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        try {

          if (this.formSubmittted) {

            await Auth.forgotPasswordSubmit(this.loginEmail, this.code, this.loginPassword);
            this.$store.commit("SET_AUTH_STATE", "")

          } else {
            await Auth.forgotPassword(this.loginEmail);

            this.formSubmittted = true
          }


          this.loading = false
        } catch (error) {
          this.loading = false
          await this.$store.dispatch('notifications/setSnackbar', {
            color: "error",
            text: error
          }, {root: true})
          throw error
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  data: () => ({
    formSubmittted: false,
    code: "",
    dialog: true,
    valid: true,
    loginPassword: "",
    loginEmail: "",
    loading: false,
    rules: {
      required: value => !!value || "Champ obligatoire",
      min: v => (v && v.length >= 8) || "8 caractères minimum"
    }

  })
}
</script>

<style scoped>

</style>