export default {
    data() {
        return {
            statusReferences: [
                {text: 'En cours', value: 'EN_COURS', color: '#fdbc64'},
                {text: 'En attente client', value: 'EN_ATTENTE_CLIENT', color: '#79affd'},
                {text: 'Stand by', value: 'STAND_BY', color: '#e8697e'},
                {text: 'Fait', value: 'FAIT', color: '#41D395'}
            ]
        }
    }
}