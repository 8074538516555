<template>
  <v-bottom-sheet
      v-model="dialogActivator">

    <v-sheet
        class=""
        height="100%"
    >

      <div class="d-flex justify-end">
        <v-btn
            class="mt-6 mr-5"
            color="red"
            text
            @click="dialogActivator = false"
        >
          Fermer
        </v-btn>
      </div>


      <v-card class="mt-8 elevation-0">

        <v-card-title class="justify-center">
          <slot name="title">{{ currentItem.name }}</slot>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text style="height: 70vh; overflow: scroll">


          <div v-if="!commentsLoading" class="py-3">

            <v-container class="pb-10">

              <!-- Comment Dialog  -->
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  persistent
              >

                <template v-slot:activator="{ on, attrs }">

                  <div class="text-center">
                    <v-btn
                        class="mb-2"
                        color="secondary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      Nouvelle note
                    </v-btn>
                  </div>


                </template>

                <v-form ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent>

                  <v-card>

                    <v-card-title class="justify-center">
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>

                      <v-text-field
                          v-model="editedItem.name"
                          autofocus
                          label="Titre"
                      ></v-text-field>

                      <v-textarea
                          v-model="editedItem.content"
                          clear-icon="mdi-close-circle"
                          clearable
                      ></v-textarea>

                      <v-file-input
                          v-if="!editedItem.file"
                          :loading="fileLoading"
                          label="Fichier"
                          @change="uploadFile"
                      ></v-file-input>

                      <v-chip
                          v-else
                          class="ma-2"
                          close
                          @click:close="removeFile()"
                      >
                        {{ editedItem.file }}
                      </v-chip>


                    </v-card-text>

                    <v-card-actions>

                      <v-spacer></v-spacer>

                      <v-btn
                          :disabled="loading"
                          color="secondary"
                          text
                          @click="close"
                      >
                        Annuler
                      </v-btn>

                      <v-btn
                          :disabled="fileLoading"
                          :loading="loading"
                          color="primary darken-1"
                          text
                          type="submit"
                          @click="save"
                      >
                        Enregistrer
                      </v-btn>

                    </v-card-actions>

                  </v-card>

                </v-form>

              </v-dialog>

              <!-- Comment Timeline  -->

              <v-row justify="center">

                <v-col cols="12" lg="6" xl="4">


                  <v-card
                      v-for="comment in comments"
                      :key="comment.id"
                      class="my-5"
                      elevation="8"
                      outlined
                  >

                    <v-card-text>

                      <div class="text-overline mb-4 d-flex justify-space-between">
                        <div class="text--accent-4">{{ formatDate(comment.createdAt) }}</div>
                        <div>{{ formatTime(comment.createdAt) }}</div>
                      </div>

                      <h5 class="text-h4 mb-1 text--primary">{{ comment.name }}</h5>

                      <vue-simple-markdown :source="comment.content"></vue-simple-markdown>

                      <v-chip
                          v-if="comment.file"
                          class="mt-5"
                          @click="openFile(comment.file)"
                      >
                        {{ comment.file }}
                      </v-chip>

                    </v-card-text>

                    <v-card-actions class="justify-end">

                      <v-icon
                          class="mr-2"
                          small
                          @click="editComment(comment)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                          small
                          @click="deleteCommentClick(comment)"
                      >
                        mdi-delete
                      </v-icon>

                    </v-card-actions>

                  </v-card>

                </v-col>


              </v-row>

            </v-container>

          </div>

          <div v-else class="text-center">
            <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </div>

        </v-card-text>

      </v-card>

    </v-sheet>


    <v-dialog v-model="dialogDelete" max-width="500px">

      <v-card>

        <v-card-title class="text-h5">Voulez-vous vraiment supprimer ce commentaire ?</v-card-title>

        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogDelete = false">Annuler</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>

      </v-card>

    </v-dialog>


  </v-bottom-sheet>

</template>

<script>

import TimeFormatterMixin from "../mixins/TimeFormatter"
import VueSimpleMarkdown from 'vue-simple-markdown'
import Vue from "vue";
import {API, Storage} from "aws-amplify";
import {commentsByPost} from "@/graphql/queries";
import {createComment, deleteComment, updateComment} from "@/graphql/mutations";

Vue.use(VueSimpleMarkdown)

// TODO: Refactoriser proprement

export default {

  props: ["showComments", "currentItem"],

  mixins: [TimeFormatterMixin],

  components: {},

  data() {
    return {
      commentsLoading: false,
      dialog: false,
      dialogDelete: false,
      valid: true,
      editedIndex: -1,
      fileLoading: false,
      fileUrl: '',
      loading: false,
      editedItem: {
        name: '',
        content: '',
        file: ''
      },
      defaultItem: {
        name: '',
        content: '',
        file: ''
      },
      comments: []
    }
  },

  watch: {
    dialogActivator: function (newVal) {
      if (newVal) {
        this.fetchComments()
      }
    }
  },


  computed: {

    dialogActivator: {

      get: function () {
        return this.showComments
      },
      set: function (newValue) {
        this.$emit("dialogUpdated", newValue)
      }
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau commentaire' : 'Modifier le commentaire'
    }
  },


  methods: {


    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async fetchComments() {

      this.commentsLoading = true


      try {


        const response = await API.graphql({
          query: commentsByPost,
          variables: {postCommentsId: this.currentItem.id, sortDirection: "DESC"}
        })

        this.comments = response.data.commentsByPost.items
        this.commentsLoading = false
      } catch (e) {
        this.commentsLoading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }
    },


    async save() {

      this.loading = true

      try {

        if (this.editedIndex > -1) {

          await API.graphql({
            query: updateComment,
            variables: {
              input: {
                id: this.editedItem.id,
                name: this.editedItem.name,
                content: this.editedItem.content,
                file: this.editedItem.file
              }
            },
          });
        } else {

          await API.graphql({
            query: createComment,
            variables: {
              input: {
                name: this.editedItem.name,
                content: this.editedItem.content,
                postCommentsId: this.currentItem.id,
                file: this.editedItem.file,
                type: "COMMENT"
              }
            },
          });
        }

        this.loading = false

      } catch (e) {
        this.loading = false
        await this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }


      this.close()
      this.fetchComments()

    },


    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.fetchComments()
    },
    editComment(item) {
      this.editedIndex = this.comments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteCommentClick(item) {
      this.editedIndex = this.comments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {

      this.commentsLoading = true

      try {
        await API.graphql({
          query: deleteComment,
          variables: {
            input: {
              id: this.editedItem.id
            }
          },
        });
      } catch (e) {
        this.commentsLoading = false
        this.$store.dispatch('notifications/setSnackbar', {
          color: "error",
          text: e
        }, {root: true})
        throw e
      }


      this.closeDelete()

    },
    async uploadFile(file) {

      if (file) {

        this.fileLoading = true
        const result = await Storage.put(file.name, file, {
          level: "private"
        })
        this.editedItem.file = result.key
        this.fileLoading = false

      }

    },

    async removeFile() {
      await Storage.remove(this.editedItem.file, {
        level: "private"
      })
      this.editedItem.file = null
    },

    async openFile(filename) {


      let s3Options = {
        level: "private"
      }

      const extension = filename.split(".").pop()

      if (extension === 'pdf') {
        s3Options.contentType = 'application/pdf'
      }

      const result = await Storage.get(filename, s3Options)

      window.open(result, '_blank');

    }

  },
}
</script>

<style lang="scss" scoped>
.markdown-body {
  white-space: pre-wrap;
}

::v-deep code {
  white-space: normal;
}
</style>