<template>

    <v-app-bar app>

      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
    
    </v-app-bar>

</template>

<script>

    import { mapActions } from "vuex";

    export default {

        methods: {
            ...mapActions({
                'setSnackbar': 'notifications/setSnackbar'
            }),
            toggleDrawer() {
                this.$store.commit('SET_DRAWER', !this.$store.state.drawer);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>